<template>
    <div class="login-wrapper">
        
        <div id="login-div">
            <div class="title-holder">
                <span>
                    Fluke
                </span>
                Portal do distribuidor <br>
                <f-expandable-div :expanded="loginFail">
                    <div class="login-error-message">
                        Combinação <strong>email/senha</strong> não é válida.
                    </div>
                </f-expandable-div>
            </div>
            <div id="form">
                <div class="login-card">
                    <input v-model="auth.email" @keyup.enter="login" placeholder="Email" />
                    <input v-model="auth.passwd" @keyup.enter="login" type="password" placeholder="Senha" />
                </div>
            </div>
            <div class="btn-aligner">
                <div class="btns-aux">
                    <!-- <span @click="forgot" class="forgot-link">
                        esqueci a senha
                    </span> -->
                </div>
                <div class="btns-main">
                    <button
                        @click="login">

                        Entrar
                    </button>
                </div>
            </div>
            <!-- <v-progress-linear indeterminate></v-progress-linear> -->
        </div>
    </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules'
import FExpandableDiv from '../../components/shared/ExpandableDiv.vue'
// f-expandable-div

export default {
    components: {
        FExpandableDiv
    },
    data: () => ({
        isValid: false,
        failPass: false,
        loginFail: false,
        auth: {
            email: '',
            passwd: ''
        }
    }),
    watch: {
        'auth.email'() {
            this.failPass = false
        },
        'auth.passwd'() {
            this.failPass = false
        }
    },
    computed: {
        required() {
            return validatorRules.required("Campo obrigatório")
        },
        loginAttempt() {
            return (this.failPass)?' ':null
        },
    },
    methods: {
        async login() {
            // this.$refs.form.validate()
            // if (!this.isValid) return false

            // return false

            const response = await this.$store.dispatch( 'auth/login', this.auth )
            if( response == false ){
                this.failPass = true
                this.loginFail = true
            } else {
                if( response.reset == false ) {
                    this.$router.push({
                        name: 'pages-home'
                    })
                } else {
                    this.$router.push({
                        name: 'login-set-password'
                    })
                }
            }
        },
        forgot() {
            this.$router.push({
                name: 'login-forgot'
            })
        }
    }
}
</script>
